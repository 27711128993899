import { render, staticRenderFns } from "./AttandanceComponent.vue?vue&type=template&id=572a7538&scoped=true&"
import script from "./AttandanceComponent.vue?vue&type=script&lang=ts&"
export * from "./AttandanceComponent.vue?vue&type=script&lang=ts&"
import style0 from "./AttandanceComponent.less?vue&type=style&index=0&id=572a7538&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "572a7538",
  null
  
)

export default component.exports